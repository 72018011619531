import Alpine from "alpinejs"
import Swiper from "swiper"
// import 'https://cdn.jsdelivr.net/npm/vidstack/dist/cdn/prod.js'
// import "~/modules/vidstack.min.js"
// import "https://cdn.jsdelivr.net/npm/vidstack@0.6.15/dist/cdn/prod.min.js"
import '~/styles/swiper-bundle.min.css'




// TODO: Migrate to new Vidstack
import 'vidstack/styles/defaults.css'
// import 'vidstack/styles/community-skin/video.css'

import { defineCustomElements } from 'vidstack/elements';

defineCustomElements();

export function heroSlider(sectionIndex) {
  console.log("herosliderindex", sectionIndex)

  return {
    init() {
      this.heroSlider.init()
      console.log("init", this.heroSlider)
    },

    heroSlider: new Swiper(`#hero-slider_${sectionIndex}`, {
      pagination: {
        el: ".swiper-pagination",
        clickable: true,
      },
      autoplay: {
        delay: 5000,
      },
    }),
  }
}

Alpine.data("heroSlider", heroSlider)
